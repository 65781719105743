import React, { useState, useEffect } from 'react'
import AgencyCard from '../home/agency-card'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import Skeleton from 'react-loading-skeleton'
import { search as searcher } from "ss-search"
import { Row, Col } from 'react-bootstrap'

const SearchCards = ({ search }) => {
  const { loading, error, data } = useQuery(APOLLO_QUERY)
  const [filteredAgencies, setFilteredAgencies] = useState([])

  const renderError = error ? <div>Eroare la încărcarea datelor</div> : <></>
  const renderSkeleton = loading ? <Skeleton height={300} count={1} width={300} /> : <></>

  const filterAgencies = (agencies, searchText: string) => {
    const searchKeys = ['name', 'description']
    const results = searcher(agencies, searchKeys, searchText)
    return results
  }

  useEffect(() => {
    if (data && data.allMediaAgencies.length) {
      const filtered = filterAgencies(data.allMediaAgencies, search)
      setFilteredAgencies(filtered)
    }
  }, [data, search])

  return (
    <>
      {renderError}
      {renderSkeleton}
      {filteredAgencies.length ? (
        <Row className="py-5">
          {filteredAgencies.map(agency =>
            <Col lg={4} md={6} xs={12} key={agency.id}>
              <AgencyCard agency={agency} />
            </Col>
          )}
        </Row>)
        :
        <Row className="py-5 justify-content-center">
          <span> Ne pare rău, nu a fost găsit nici un rezultat pentru această căutare</span>
        </Row>
      }
    </>
  )
}

export default SearchCards

const APOLLO_QUERY = gql`{
  allMediaAgencies {
    id
    name
    photo {
      publicUrl
    }
    icon {
      publicUrl
    }
    path
    description
    verdicts {
      id
      createdAt
      verdictDate
      disponibility
      declaration
      norms
      quality
      usability
      total
      notes
      credibilityLabel
    }
    url
  }
}
`
