import React, { useState, useEffect } from 'react'
import SearchForm from '../home/search-form'
import SearchCards from './search-cards'
import { useQueryParam, StringParam } from 'use-query-params'
import { Container } from 'react-bootstrap'

const SearchResults = ({ searchTerm }: { searchTerm: string }): JSX.Element => {
  const [searchParam, setSearchParam] = useQueryParam('q', StringParam)
  const [search, setSearch] = useState(searchTerm || searchParam || '')
  const [searchAgencies, setSearchAgencies] = useState(search)
  const onChangeSearchTerm = (event) => {
    setSearch(event.target.value)
  }

  useEffect(() => {
    setSearchParam(search)
  }, [])

  const onSubmit = (event) => {
    event.preventDefault()
    setSearchParam(search)
    setSearchAgencies(search)
  }
  return (
    <Container>
      <SearchForm searchTerm={search}
        onChange={onChangeSearchTerm}
        onSubmit={onSubmit} />
      <SearchCards search={searchAgencies} />
    </Container>
  )
}

export default SearchResults
