import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SearchResults from "../components/search-results/search-results"
import { useIntl } from "gatsby-plugin-intl"

const SearchPage = ({ location }): JSX.Element => {
  const searchFromLocation = location && location.state && location.state.searchTerm ? location.state.searchTerm : ''
  const intl = useIntl()

  return <Layout>
    <SEO
      title={intl.formatMessage({ id: 'search.title' })}
    />
    <SearchResults searchTerm={searchFromLocation} />
  </Layout>

}

export default SearchPage
